<template>
  <div id="cashClose">
    <v-row align="center">
      <v-col
        cols="12"
        sm="6"
        md="5"
        :align="$vuetify.breakpoint.smAndDown ? 'center' : ''"
        class="py-0"
      >
        <v-btn-toggle class="mb-md-0" mandatory v-model="tab">
          <v-btn
            outlined
            height="30px"
            value="today"
            @click="
              today = real_today;
              fetch();
            "
            >Hoy</v-btn
          >
          <v-menu v-model="menu">
            <template v-slot:activator="{ on }">
              <v-btn value="fechas" outlined height="30px" v-on="on">Día</v-btn>
            </template>

            <v-date-picker
              first-day-of-week="1"
              v-model="today"
              no-title
              scrollable
              color="primary"
              @input="
                menu = false;
                fetch();
              "
            ></v-date-picker>
          </v-menu>
        </v-btn-toggle>
      </v-col>
      <v-col class="py-0 px-2" cols="5" md="2">
        <ChangeCashRegister />
      </v-col>
      <v-col class="py-0 px-0" cols="5" md="2">
        <OpenCloseCash
          :total_teoric="cash_total.total_teoric"
          @update="fetch"
        />
      </v-col>
      <v-col cols="2">
        <cash-list :total_teoric="cash_total.total_teoric" @update="fetch" />
      </v-col>
      <v-col cols="2" md="1" class="text-start">
        <v-dialog width="500" v-model="dialog_gasto">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              :class="$vuetify.breakpoint.xs ? 'order-1' : ''"
            >
              <v-icon size="36">mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title> ¿Ingreso o Gasto? </v-card-title>

            <div class="close">
              <v-btn icon @click="dialog_gasto = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-row id="selector">
                <v-col>
                  <v-card @click="$refs.ingreso.select_dialog = true">
                    <v-card-title
                      class="py-9"
                      style="
                        font-size: 18px !important;
                        text-decoration: none !important;
                      "
                      >Ingreso</v-card-title
                    >
                  </v-card>
                </v-col>

                <v-col>
                  <v-card @click="$refs.gasto.select_dialog = true">
                    <v-card-title
                      class="py-9"
                      style="
                        font-size: 18px !important;
                        text-decoration: none !important;
                      "
                      >Gasto</v-card-title
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <ExpensesForm
          ref="ingreso"
          :showAddButton="false"
          :expense="expense"
          :action="'save'"
          @update="fetch"
          @to_debit="$emit('to_debit')"
          type="confirmed_ingresos"
          @open_price="$emit('open_price')"
        />
        <ExpensesForm
          ref="gasto"
          :showAddButton="false"
          :expense="expense"
          :action="'save'"
          @update="fetch"
          @to_debit="$emit('to_debit')"
          type="confirmed_gastos"
          @open_price="$emit('open_price')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9" class="py-0 px-1">
        <v-row class="mx-0">
          <v-col class="py-0 px-1 pb-4" cols="12" md="6">
            <v-card id="resume-before" class="my-2">
              <v-card-title
                class="py-2"
                style="
                  cursor: pointer;
                  text-decoration: underline;
                  display: flex;
                  justify-content: center;
                "
                >{{ $t("balance_day_before") }}
              </v-card-title>
              <v-card-text class="py-2">
                <v-simple-table class="align_start_end">
                  <tr></tr>
                  <tr>
                    <td>{{ $t("cash") }}</td>
                    <td>{{ $n(before.cash, "currency") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("card_online") }}</td>
                    <td>{{ $n(before.card, "currency") }}</td>
                  </tr>

                  <tr>
                    <td class="primary--text font-weight-bold text-uppercase">
                      {{ $t("total") }}
                    </td>
                    <td class="primary--text font-weight-bold">
                      {{ $n(before.card + before.cash, "currency") }}
                    </td>
                  </tr>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="py-0 px-1 pb-4" cols="12" md="6">
            <v-card id="resume-debid" class="my-2">
              <v-card-title
                class="py-2"
                style="
                  cursor: pointer;
                  text-decoration: underline;
                  display: flex;
                  justify-content: center;
                "
                >{{ $t("actual_debt") }}</v-card-title
              >
              <v-card-text class="py-2">
                <v-simple-table class="align_start_end">
                  <tr></tr>
                  <tr>
                    <td>{{ $tc("artist", 1) }}</td>
                    <td>{{ $n(debit.artist, "currency") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $tc("customer", 1) }}</td>
                    <td>{{ $n(debit.customer, "currency") }}</td>
                  </tr>
                  <tr>
                    <td class="primary--text font-weight-bold text-uppercase">
                      {{ $t("total") }}
                    </td>
                    <td class="primary--text font-weight-bold">
                      {{ $n(debit.customer + debit.artist, "currency") }}
                    </td>
                  </tr>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-for="(c, i) in list"
            :key="i"
            class="py-0 px-1 pb-4"
          >
            <v-card id="cash-list" class="my-2">
              <v-card-title
                class="py-2"
                style="
                  cursor: pointer;
                  text-decoration: underline;
                  display: flex;
                  justify-content: center;
                "
              >
                {{ $t("expenses." + c) }}
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items(c)"
                :footer-props="{
                  'items-per-page-text': '',
                }"
              >
                <template v-slot:item.cantidad="{ item }">
                  <span>
                    {{ $n(item.cantidad, "currency") }}
                  </span>
                </template>
                <template v-slot:item.total_acum="{ item }">
                  <span>
                    {{ $n(item.total_acum, "currency") }}
                  </span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="py-0 px-1 pb-4">
        <v-card id="total-cash" class="my-2" raised elevation="6">
          <v-card-title
            class="py-2"
            style="
              cursor: pointer;
              text-decoration: underline;
              display: flex;
              justify-content: center;
            "
            >{{ $t("total") }}</v-card-title
          >
          <v-card-text>
            <v-tabs v-model="tabs">
              <v-tab href="#cash">{{ $t("cash") }}</v-tab>
              <v-tab href="#card">{{ $t("card_online") }}</v-tab>
              <v-tab href="#all" class="text-uppercase">{{
                $t("total")
              }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item value="cash">
                <v-simple-table class="align_start_end">
                  <tr></tr>
                  <tr>
                    <td>{{ $tc("appointment", 2) }}</td>
                    <td>{{ $n(cash_total.appointments, "currency") }}</td>
                  </tr>

                  <tr>
                    <td>{{ $t("inventory") }}</td>
                    <td>{{ $n(cash_total.inventory, "currency") }}</td>
                  </tr>

                  <tr>
                    <td>{{ $t("others") }}</td>
                    <td>{{ $n(cash_total.others, "currency") }}</td>
                  </tr>

                  <tr class="border-top border-bottom">
                    <td>{{ $t("exists") }}</td>
                    <td>{{ $n(cash_total.outcomes, "currency") }}</td>
                  </tr>

                  <tr>
                    <td class="text-no-wrap">Total Teórico</td>
                    <td>{{ $n(cash_total.total_teoric, "currency") }}</td>
                  </tr>

                  <tr>
                    <td>{{ $t("theoric_total") }}</td>
                    <td>{{ $n(total_real, "currency") }}</td>
                  </tr>

                  <tr class="border-top">
                    <td>{{ $t("mismatch") }}</td>
                    <td>
                      {{ $n(cash_total.total_teoric - total_real, "currency") }}
                    </td>
                  </tr>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item value="card">
                <v-simple-table class="align_start_end">
                  <tr>
                    <td>{{ $tc("appointment", 1) }}</td>
                    <td>{{ $n(online_total.appointments, "currency") }}</td>
                  </tr>

                  <tr>
                    <td>{{ $t("inventory") }}</td>
                    <td>{{ $n(online_total.inventory, "currency") }}</td>
                  </tr>

                  <tr>
                    <td>{{ $t("others") }}</td>
                    <td>{{ $n(online_total.others, "currency") }}</td>
                  </tr>

                  <tr class="border-top">
                    <td>{{ $t("exists") }}</td>
                    <td>{{ $n(online_total.outcomes, "currency") }}</td>
                  </tr>

                  <tr class="border-top">
                    <td>{{ $t("total") }}</td>
                    <td>{{ $n(online_total.total, "currency") }}</td>
                  </tr>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item value="all">
                <v-simple-table class="align_start_end">
                  <tr>
                    <td>Citas</td>
                    <td>{{ $n(all_total.appointments, "currency") }}</td>
                  </tr>

                  <tr>
                    <td>Inventario</td>
                    <td>{{ $n(all_total.inventory, "currency") }}</td>
                  </tr>

                  <tr>
                    <td>Otros</td>
                    <td>{{ $n(all_total.others, "currency") }}</td>
                  </tr>

                  <tr class="border-top">
                    <td>Salidas</td>
                    <td>{{ $n(all_total.outcomes, "currency") }}</td>
                  </tr>

                  <tr class="border-top">
                    <td>Total</td>
                    <td>{{ $n(all_total.total, "currency") }}</td>
                  </tr>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as moment from "moment";
export default {
  name: "CashClose",
  mounted() {
    this.real_today = moment().format("YYYY-MM-DD");
    this.today = moment().format("YYYY-MM-DD");
    this.fetch();
  },
  components: {
    CashOutput: () => import("@/components/expense/CashOutput"),
    ChangeCashRegister: () => import("@/components/expense/ChangeCashRegister"),
    CashList: () => import("@/components/expense/cash/CashList"),
    ExpensesForm: () => import("@/components/expense/Form"),
    OpenCloseCash: () => import("./cash/OpenCloseCash"),
  },
  data() {
    return {
      dialog_gasto: false,
      dialog: false,
      today: null,
      real_today: null,
      tabs: "cash",
      cash_total: {},
      online_total: {},
      all_total: {},
      before: {
        card: 0,
        cash: 0,
      },
      menu: false,
      debit: {},
      list: ["cash", "card"],
      items_card: [],
      items_cash: [],
      expense: {},
      headers: [
        {
          text: this.$tc("expenses.concepto", 2),
          align: "center",
          sortable: false,
          value: "conceptos",
        },
        {
          text: this.$t("expenses.cantidad"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
        {
          text: this.$t("expenses.total_acum"),
          align: "center",
          sortable: false,
          value: "total_acum",
        },
      ],
    };
  },
  computed: {
    ...mapState("expenses", ["filtersState"]),
    tab: {
      get() {
        return this.today === this.real_today ? "today" : "dates";
      },
      set(value) {},
    },
    total_real() {
      return 0;
    },
  },
  watch: {
    filtersState: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  methods: {
    sortNumbers(a, b) {
      a = +a;
      b = +b;
      if (a < b) return 1;
      else if (a === b) return 0;
      else return -1;
    },
    items(c) {
      return this[`items_${c}`];
    },
    fetch() {
      this.fetchInfo();
      this.list.forEach((c) => {
        this.fetchList(c);
      });
    },
    ...mapActions("expenses", ["getExpensesCashClose", "getExpenses"]),
    fetchInfo() {
      this.getExpensesCashClose({
        date: this.today,
        cash_register_id: this.filtersState.cash_register_id,
      }).then((data) => {
        console.log(data);
        Object.keys(data).forEach((key) => {
          this[key] = data[key];
        });
      });
    },
    fetchList(pay_mode) {
      this.getExpenses({
        filters: {
          from: this.today,
          to: this.today,
          itemPerPage: -1,
          no_order: true,
          pay_mode,
          cash_register_id: this.filtersState.cash_register_id,
        },
      }).then((data) => {
        let processed = [];
        let total_acum = this.before[pay_mode];
        data.data.forEach((e) => {
          total_acum += e.cantidad;
          processed.unshift({ ...e, total_acum });
        });
        this[`items_${pay_mode}`] = processed;
      });
    },
  },
};
</script>

<style lang="sass">
#total-cash,
#total-online,
#resume-debid,
#resume-before,
#cash-list,
#card-list
  h4
    font-size: 15px
    margin: auto
    text-transform: uppercase
    font-weight: 100
    text-decoration: underline
  @media (min-width: 960px)
    .v-data-table
      td,
      th,
      .v-data-footer
        height: unset
        padding: 5px
        background-color: #1e1e1e
        &:nth-child(2)
          text-align: end
      .v-data-footer
        border-radius: 0 0 10px 10px
      .border-top
        >td
          border-top: 1px solid var(--v-primary-base)
      .border-bottom
        >td
          border-bottom: 1px solid var(--v-primary-base)
#total-cash,
#total-online,
#resume-debid,
#resume-before
  td:first-child
    max-width: 100px
#cash-close
  td
    padding: 5px
    height: unset
    .colorBarrita
      > .v-input__control
        > .v-input__slot
          height: 18px
          &:before,
          &:after
            height: 1px
            background-color: var(--v-primary-base) !important
            border-color: var(--v-primary-base) !important
          input
            text-align: center

#resume-debid,
#resume-before
  .v-data-table
    background-color: #1e1e1e
    .v-data-table__wrapper
      margin-right: 27px
      margin-left: 27px

span#actions
  position: absolute
  bottom: 14px
  right: 5px
#selector
  .v-card
    border: 1px solid var(--v-primary-base)
    .v-card__title
      padding: 24px
#cash-list
  tbody
    tr:first-child:not(.v-data-table__empty-wrapper) td:last-child
      color: var(--v-primary-base)
  .v-card__title
    text-transform: uppercase

  .v-card__text
    background-color: #1e1e1e
</style>
